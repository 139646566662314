// First order of buisness -> Remove no-js class from html element
document.documentElement.classList.remove("no-js");

// Global components
import stickyHeader from "./global/stickyHeader.js";
stickyHeader();

import headerLanguagePanel from "./global/headerLanguagePanel.js";
headerLanguagePanel();

import headerAlertPanel from "./global/headerAlertPanel.js";
headerAlertPanel();

import navigationAndSearch from "./global/navigationAndSearch.js";
navigationAndSearch();

import languageSelector from "./global/languageSelector.js";
languageSelector();

// Blocks
import "./blocks/heroBlock.js";

import "./blocks/faqBlock.js";

import "./global/pageHero.js";

import "./blocks/imageTextBlock.js";

// vendor files
import "./vendor/iframeResize.js";
