export default function languageSelector() {
    const languageSelectors = document.querySelectorAll('.languageSelector .languageToggle');

    // If no menu buttons were found, do nothing
    if (languageSelectors.length === 0) {
        return;
    }

   // For each languagSelector listen for clicks
    languageSelectors.forEach(languageSelector => {
        languageSelector.addEventListener('click', () => {
            // Toggle the aria-expanded attribute on the button
            languageSelector.setAttribute('aria-expanded', languageSelector.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            
            // Toggle the class active on the next element wich is the language dropdown
            languageSelector.nextElementSibling.classList.toggle('active');
        });
    });
}